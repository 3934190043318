import { types } from 'mobx-state-tree';

const DoorsModel = types
  .model('DoorsModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    door_class: types.optional(types.string, ''),
    door_fire_protection_classification: types.optional(types.string, ''),
    door_type: types.optional(types.string, ''),
    door_knob_position: types.optional(types.string, ''),
    door_display: types.optional(types.string, ''),
    door_filling: types.optional(types.string, ''),
    door_frame: types.optional(types.string, ''),
    door_fitting: types.optional(types.string, ''),
    door_finish: types.optional(types.string, ''),
    door_color: types.optional(types.string, ''),
    door_height: types.optional(types.integer, 0),
    door_width: types.optional(types.integer, 0)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default DoorsModel;
