import { types } from 'mobx-state-tree';

const DoorDrawingModel = types
  .model('DoorDrawingModel', {
    id: types.optional(types.string, '1'),
    containerWidth: types.optional(types.number, 0),
    containerHeight: types.optional(types.number, 0),
    x: types.optional(types.number, 0),
    y: types.optional(types.number, 0),
    isDragging: types.optional(types.boolean, false),
    height: types.optional(types.number, 0),
    width: types.optional(types.number, 0),
    thickness: types.optional(types.number, 20),
    scale: types.optional(types.number, 0.2),
    doorKnobPosition: types.optional(types.string, ''),
    doorDisplay: types.optional(types.string, ''),
    doorDisplayThickness: types.optional(types.number, 10)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setPosition(x, y) {
      self.x = x;
      self.y = y;
    },

    setDoorDisplayThicness(thickness) {
      self.doorDisplayThickness = thickness;
    },

    setdoorDisplay(doorDisplay) {
      self.doorDisplay = doorDisplay;
    },

    setDoorKnobPosition(doorKnobPosition) {
      self.doorKnobPosition = doorKnobPosition;
    },

    setWidth(width) {
      self.width = width * self.scale;
    },
    setHeight(height) {
      self.height = height * self.scale;
    },
    setContainerWidth(width) {
      self.containerWidth = width;
    },
    setContainerHeight(height) {
      self.containerHeight = height;
    },

    setX(x) {
      self.x = x;
    },

    setY(y) {
      self.y = y;
    }
  }));

export default DoorDrawingModel;
