import { lazy } from 'react';

const DoorsHeader = lazy(() => import('./DoorsHeader'));
const DoorsTable = lazy(() => import('./DoorsTable'));
const AddOrUpdateDoorsModal = lazy(() => import('./AddOrUpdateDoorsModal'));
const DoorsForm = lazy(() => import('./DoorsForm'));
const SizeForm = lazy(() => import('./SizeForm'));
const WindowDrawing = lazy(() => import('./WindowDrawing'));

export { DoorsHeader, DoorsTable, AddOrUpdateDoorsModal, DoorsForm, SizeForm, WindowDrawing };
