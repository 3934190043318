import React from 'react';
import { BuildOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <BuildOutlined />,
    route: Routes.DOORS_ROUTE,
    menuName: 'Doors'
  }
];

export default AppMenus;
