import { types } from 'mobx-state-tree';
import {  DoorsManagementUtilities, DoorsState } from '@app_modules/Doors/model';


const AppStore = {

  doors: types.optional(DoorsState, {}),
DoorsManagementUtilities: types.optional(DoorsManagementUtilities, {}),


};

export default AppStore;
