import { types, cast } from 'mobx-state-tree';
import DoorDrawingModel from './DoorDrawingModel';

const DoorsManagementUtilities = types
  .model('DoorsUtilities', {
    showAddDoorsModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), []),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    selectedRowsKeys: types.optional(types.array(types.string), []),
    isDownloading: types.optional(types.boolean, false),
    doorDrawing: types.optional(DoorDrawingModel, {})
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateDoorsModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateDoorsModal = !self.showAddOrUpdateDoorsModal;
    },

    setSelectedRowsKeys(selectedRowsKeys) {
      self.selectedRowsKeys = cast(selectedRowsKeys);
    },

    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    },

    getDateFormat(locale) {
      switch (locale) {
        case 'de':
          return 'DD.MM.YYYY';
        default:
          return 'DD-MM-YYYY';
      }
    }
  }));

export default DoorsManagementUtilities;
