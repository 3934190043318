import { types, flow } from 'mobx-state-tree';
import DoorsModel from './DoorsModel';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
import axios from 'axios';

const API_PATH = '/api/doors';
const API_EXPORT_PATH = '/api/doors/export_csv';

export default types
  .model('DoorsState', {
    state: types.optional(types.array(DoorsModel), []),
    singleState: types.optional(DoorsModel, {}),

    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),

    setTotal: (total) => {
      self.total = total;
    },

    exportData: flow(function* () {
      return yield axios.get(API_EXPORT_PATH);
    }),

    getCSV: flow(function* () {
      return yield axios({
        url: '/public/export/export_file.csv', //your url
        method: 'GET',
        responseType: 'blob' // important
      });
    }),

    deleteFile: flow(function* () {
      yield axios.delete('/api/feedback/delete_csv');
    })
  }))
  .views((self) => ({}));
